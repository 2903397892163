import React from 'react';
import Routers from './routes';

function App() {
  return (
    <Routers />
  );
}

export default App;
